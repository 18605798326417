<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <el-row>
                                    <el-col :span="24" align="right" >
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_btn">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="4" v-if="view.order_status == 'waiting'">Approve</el-dropdown-item>
                                          <el-dropdown-item :command="1" v-if="view.order_status != 'waiting'">Print</el-dropdown-item>
                                          <el-dropdown-item :command="2" v-if="view.order_status != 'waiting'">Download</el-dropdown-item>
                                          <el-dropdown-item :command="3" v-if="view.order_status != 'waiting'">Send Email</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row>
                                <el-row>
                                    <el-col :span="12" align="left">
                                        <span v-loadimage="store_address.website_logo?store_address.website_logo:''"  ></span>
                                    </el-col>
                                    <el-col :span="12" align="right">
                                            <b >
                                    <label class="mt-10">
                                      {{store_address.address?store_address.address:''}}, 
                                      {{store_address.city_details? store_address.city_details.city_name: ''}}, 
                                      {{store_address.state_details? store_address.state_details.state_name:''}} -{{store_address.post_code}}</label><br>
                                    <label>Phone: {{store_address.phone?store_address.phone:''}}</label><br>
                                    <label>Fax: {{store_address.fax?store_address.fax:''}}</label><br>
                                    <label>Email: {{store_address.email_address?store_address.email_address:''}}</label>
                                    </b>
                                    <br>
                                    </el-col>
                                  </el-row>
                                   <hr class="invoice-line">
                                  <el-row>                                     
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>PURCHASE ORDER</b></h2>
                                      </el-col>                                                                        
                                  </el-row>
                                   <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>PURCHASE ORDER #</b></span><span><b> {{view.po_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>SUPPLIER ID : </b></span><span><b>{{view.supplier.supplier_Id}}</b></span>
                                       </el-col>
                                  </el-row>
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO :</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name" >{{view.supplier.company_name}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && (view.supplier.unit_no || view.supplier.street)">{{view.supplier.street}}{{view.supplier.unit_no ? ', Unit No : '+view.supplier.unit_no :''  }} </div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.city">{{view.supplier.city? view.supplier.city.city_name : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.state">{{view.supplier.state? view.supplier.state.state_name + (view.supplier.postal_code ? ' - '+view.supplier.postal_code : ''):''}}</div>
                                      </el-col>
                                       <el-col :span="8" align="">
                                            .
                                      </el-col>
                                       <el-col :span="8" align="left">                                          
                                         <div class="billing-adr" style="padding-left:10px;" v-if="view.delivery_type == 'Delivery'">SHIP TO :</div>
                                         <div class="billing-adr" style="padding-left:10px;" v-else>PickUp :</div>
                                         <div style="padding: 3px;" v-if="view.store_details && view.store_details.website_name && view.delivery_type == 'Delivery'">{{view.store_details?view.store_details.website_name:''}}</div>
                                         <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name && view.delivery_type == 'PickUp'">{{view.supplier.company_name}}</div>
                                         <div style="padding: 3px;" v-if="view.address || view.unit_no">{{view.address}}{{view.unit_no ? ', Unit No : '+view.unit_no :''  }}</div>
                                          <div style="padding: 3px;" v-if="view.city && view.city.city_name ">{{view.city? view.city.city_name : '' }}</div>
                                          <div style="padding: 3px;" v-if="view.state && view.state.state_name ">{{view.state.state_name +(view.post_code ? ' - '+view.post_code : '')}}</div>
                                      </el-col>
                                    </el-row>
                                     <div class="row">
                                      <div class="col-xl-12 ">
                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >DATE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >REQUEST DATE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >PURCHASER</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SHIP VIA</div></th>
                                                    <th aria-colindex="2" role="cell"><div >F.O.B</div></th>
                                                    <th aria-colindex="2" role="cell"><div >TERMS</div></th>
                                                </tr>
                                                <tr class="billing-cl3">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{format_date(view.created_at)}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{format_date(view.request_date)}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{view.created_by}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{view.ship_via}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{view.fob}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{view.term?view.term.term_name:''}}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                          <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >DESCRIPTION</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Type</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Qty</div></th>                                                    
                                                    <th aria-colindex="2" role="cell"><div >Price</div></th>                                                    
                                                    <th aria-colindex="2" role="cell"><div >Amount</div></th>
                                                </tr>
                                                   <tr class="billing-cl3" v-for="item in view.products" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.supplier_sku?item.supplier_sku:''}}</div></td>
                                                    <td aria-colindex="1" role="cell" ><div class="padding-10">{{item.product_item?item.product_item.name:''}} -
                                                      
                                                        <el-popover trigger="hover" placement="top-start">
                                                           
                                                            <div><b>SKU: {{ item.product_item.sku }}</b></div>
                                                            <div v-for="(prod_image, index) in item.product_item.attachments" :key="index" >
                                                              <img :src="image_url +'product_images/'+ prod_image['file_name']" class="prod_image">
                                                              <p>{{ item.product_item.name }}</p>
                                                            </div>
                                                            <span slot="reference" class="name-wrapper">
                                                              <el-tag size="small"><b>{{ item.product_item.sku }}</b></el-tag>
                                                            </span>
                                                          </el-popover>
                                                    </div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.type_id}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{item.quantity}}</div></td>                                                    
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">$ {{parseFloat(item.unit_price).toFixed(2)}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-right pr-5"><div>${{parseFloat(item.quantity * item.unit_price).toFixed(2)}}</div></td>
                                                </tr>
                                                <tr class="billing-cl3">
                                                  <td aria-colindex="2" role="cell" colspan="4" >
                                                      <div class="padding-10"><b><u>Other Comments or Special Instructions</u></b></div>
                                                      <div class="padding-10">{{view.comments}}</div>
                                                      <div class="padding-10" style="margin-top:40px;"></div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" >
                                                      <div class="padding-10">Sub Total</div>
                                                      <div class="padding-10">Tax Total ({{view.tax_per}}%)</div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10">Total</div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" class="text-center">
                                                      <div class="padding-10" style="text-align:right">${{parseFloat(view.sub_total).toFixed(2)}}</div>
                                                      <div class="padding-10" style="text-align:right">
                                                         <span v-if="view.tax_total>0">$</span> 
                                                          <span v-if="view.tax_total>0" >{{parseFloat(view.tax_total).toFixed(2)}}</span>
                                                          <span v-else>--</span>
                                                       </div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10" style="text-align:right">${{parseFloat(view.order_total).toFixed(2)}}</div>
                                                      </td>  
                                                </tr>
                                            </tbody>
                                        </table>
                                      </div>
                                     </div>
                                     <div class="mt-5" >
                                        <el-tabs class="root-tab" type="card" v-model="activeTab">
                                          <el-tab-pane label="BACK ORDERS" name="back_orders"  v-if="view.back_order !=''"> 
                                            <div>
                                                <el-table :data="view.back_order" element-loading-text="Loading" stripe border fit>
                                              <el-table-column label="Back Order #"  width="150" >
                                                <template slot-scope="scope">
                                                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewBackorder(scope.row.po_id,scope.row.order_status,scope.row.id)">{{(scope.row.back_order_number) ? scope.row.back_order_number : ""}}</a></el-tag>                
                                                </template>
                                              </el-table-column> 
                                              <el-table-column label=" PO #"  width="120" >
                                                <template slot-scope="scope">
                                                  {{(scope.row.po_number) ? scope.row.po_number : ""}}              
                                                </template>
                                              </el-table-column>                         
                                              <el-table-column label="Order Created" prop="customer_name"  min-width="200" >
                                                <template slot-scope="scope">
                                                {{scope.row.created_by?scope.row.created_by:'--'}}
                                                </template>
                                              </el-table-column>  
                                              <el-table-column label="Created Date" prop="delivery_date"  width="120" >
                                                <template slot-scope="scope">
                                                {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                                                </template>
                                              </el-table-column> 
                                              <el-table-column label=" Order Status"  width="150" prop = "status" align="center" >
                                                <template slot-scope="scope">   
                                                    <span v-if="scope.row.order_status == 'completed'" class="active">Completed</span>  
                                                    <span v-if="scope.row.order_status == 'processing'" class="active">Processing</span>               
                                                    <span v-if="scope.row.order_status == 'partial_process'" class="red">Partial Received</span>
                                                    <span v-if="scope.row.order_status == 'backorder'" class="red">BackOrder</span>  
                                                    <span v-if="scope.row.order_status == 'cancel'" class="red">Cancel Order</span>                 
                                                    </template>
                                              </el-table-column>
                                              <el-table-column fixed="right" align="center" label="Actions" width="80">
                                                <template slot-scope="scope">
                                                  <el-dropdown @command="BackOrderhandleActions($event,scope.row)" size="medium">
                                                    <i class="flaticon2-menu-1"></i>
                                                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                                                      <el-dropdown-item :command="1"><i class="fa fa-eye"></i>View</el-dropdown-item>                                                      
                                                    </el-dropdown-menu>
                                                  </el-dropdown>
                                                </template>
                                              </el-table-column>
                                            </el-table> 
                                            </div>  
                                          </el-tab-pane>  
                                          
                                          <el-tab-pane label="CANCEL ORDERS" name="cancel_orders"  v-if="view.cancel_order !=''"> 
                                            <div>
                                                <el-table :data="view.cancel_order" element-loading-text="Loading" stripe border fit>
                                              <el-table-column label="Cancel Order #"  width="150" >
                                                <template slot-scope="scope">
                                                  <el-tag slot="reference" size="mini"><a href="#"  @click.prevent="viewCancelPo(scope.row.id)">{{(scope.row.cancel_order_number) ? scope.row.cancel_order_number : ""}}</a></el-tag>                
                                                </template>
                                              </el-table-column>
                                                
                                                <el-table-column label=" Cancel Amount"  width="120" >
                                                  <template slot-scope="scope">
                                                    <span>${{(scope.row.cancel_amount) ? parseFloat(scope.row.cancel_amount).toFixed(2) : ""}}</span>              
                                                  </template>
                                                </el-table-column>    
                                                 <el-table-column label=" Cancel Reason"  min-width="200" >
                                                  <template slot-scope="scope">
                                                    <span>{{(scope.row.cancel_reason) ? scope.row.cancel_reason : ""}}</span>              
                                                  </template>
                                                </el-table-column> 
                                                 <el-table-column label="Cancel Date" prop="delivery_date"  width="120" >
                                                <template slot-scope="scope">
                                                {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                                                </template>
                                              </el-table-column>
                                              <el-table-column fixed="right" align="center" label="Actions" width="80">
                                                <template slot-scope="scope">
                                                  <el-dropdown @command="CancelOrderhandleActions($event,scope.row)" size="medium">
                                                    <i class="flaticon2-menu-1"></i>
                                                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                                                      <el-dropdown-item :command="1"><i class="fa fa-eye"></i>View</el-dropdown-item>                                                      
                                                    </el-dropdown-menu>
                                                  </el-dropdown>
                                                </template>
                                              </el-table-column>
                                                </el-table>
                                            </div>
                                          </el-tab-pane>   
                                            <el-tab-pane label="RECEIVE PO" name="receive_po"  v-if="view.receive_order !=''"> 
                                            <div>
                                                <el-table :data="view.receive_order" element-loading-text="Loading" stripe border fit>
                                              <el-table-column label="Receive PO #"  width="150" >
                                                <template slot-scope="scope">
                                                  <el-tag slot="reference" size="mini"><a href="#"  @click.prevent="viewReceivePo(scope.row.id)">{{(scope.row.receive_order_id) ? scope.row.receive_order_id : ""}}</a></el-tag>                
                                                </template>
                                              </el-table-column>
                                               
                                                <el-table-column label=" Received Date"  width="120" >
                                                  <template slot-scope="scope">                                                    
                                                    <span>{{format_date(scope.row.created_at)}}</span>              
                                                  </template>
                                                </el-table-column>    
                                                 <el-table-column label=" Received By"  width="150" >
                                                  <template slot-scope="scope">
                                                    <span>{{(scope.row.receive_verified_by) ? scope.row.receive_verified_by : ""}}</span>              
                                                  </template>
                                                </el-table-column> 
                                                <el-table-column label=" Received Document"  width="150" >
                                                  <template slot-scope="scope">
                                                    <img v-if="scope.row.receive_document" :src="image_url +scope.row.receive_document" class="avatar">
                                                  </template>
                                                </el-table-column>
                                                <el-table-column label=" comments"  min-width="200" >
                                                  <template slot-scope="scope">
                                                    <span>{{(scope.row.comments) ? scope.row.comments : ""}}</span>              
                                                  </template>
                                                </el-table-column> 
                                              <el-table-column fixed="right" align="center" label="Actions" width="80">
                                                <template slot-scope="scope">
                                                  <el-dropdown @command="ReceiveOrderhandleActions($event,scope.row)" size="medium">
                                                    <i class="flaticon2-menu-1"></i>
                                                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                                                      <el-dropdown-item :command="1"><i class="fa fa-eye"></i>View</el-dropdown-item>                                                      
                                                    </el-dropdown-menu>
                                                  </el-dropdown>
                                                </template>
                                              </el-table-column>
                                                </el-table>
                                            </div>
                                          </el-tab-pane>                                             
                                        </el-tabs>   
                                    </div>
                            </div>
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getPurchaseorder, downloadPdf} from "@/api/purchaseorder";
import SendEmail from '@/components/SendEmail';
import ApprovePO from '@/components/PurchaseOrder/approve';
import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[], 
      store_address : [],     
      view_type: 'purchaseorder',
      activeTab:'back_orders',
      cancel_list:[],
      back_order:[],
      image_url :process.env.VUE_APP_BASE_IMAGE_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Purchase Orders", route: "/purchase-order/list" }, 
      { title: "View Orders" }
    ]);
     globalEvents.$on("approve", (added) => {
        this.fetchData(1);
          if (added) {
            this.fetchData(1);        
          }
    });
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getPurchaseorder(this.$route.params.poId).then(response => { 
        this.view = response.data.data 
        this.store_address = response.data.data.store_details     
        this.loading = false
      });
     
    },
     printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    }, 
     handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.poId, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'purchaseorder.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.supplier;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }else if(evt === 4){
            this.$modal.show(ApprovePO, {po_id : this.$route.params.poId}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          }
    },
    BackOrderhandleActions(evt, row){
      if (evt === 1){
          this.$router.push({path:'/back-purchase-order/view/'+row.id})
      }
      else if (evt === 2){
          this.$router.push({path:'/add/recive/purchase/order/'+row.po_id})
      }
    },
    CancelOrderhandleActions(evt, row){
      if (evt === 1){
          this.$router.push({path:'/cancel_purchaseorder_view/view/'+row.id})
      }
      
    },
    ReceiveOrderhandleActions(evt, row){
      if (evt === 1){
          this.$router.push({path:'/recive/purchase/order/'+row.id})
      }
      
    },
     viewReceivePo(id){
        this.$router.push({path:'/recive/purchase/order/'+id})
      },
    viewBackorder(purchase_id,status,backorder_id){
      if(status=='partial_process')
      this.$router.push({path:'/add/recive/purchase/order/'+purchase_id})
      else
        this.$router.push({path:'/back-purchase-order/view/'+backorder_id})
    }, 
    viewCancelPo(cancel_id){
      this.$router.push({path:'/cancel_purchaseorder_view/view/'+cancel_id})
    }  
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 300px;
      margin-top: 30px;
      margin-left: 10px;
    }
    .avatar {
    width: 75px !important;
    height: 25px !important;
    display: block;
  }
  .prod_image{
    width: 100px !important;
    height: 100px !important;
    display: block;
  }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    

}
 
</style>